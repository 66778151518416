import React from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout"

const page404 = () => (
  <Layout>
    <div className="container">
      <h2 className="margin-y-md text-lg font-heading font-bold">
        Esta página no existe
      </h2>
      <div className="text-sm margin-bottom-lg">
        <p>La página que busca no la hemos encontrado.</p>
        <p>
          {" "}
          Vuelva a intentar <Link to="/">desde el principio</Link>.
        </p>
      </div>
    </div>
  </Layout>
)

export default page404
